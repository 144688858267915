@font-face {
  font-family: "Regular";   /*Can be any text*/
  src: local("Regular"),
    url("./assets/font/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Light";   /*Can be any text*/
  src: local("Light"),
    url("./assets/font/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Bold";   /*Can be any text*/
  src: local("Bold"),
    url("./assets/font/Montserrat-Bold.ttf") format("truetype");
}

body{
  font-family: "Regular" !important;
}
.black{
  background-color: #000000;
}
.nav-custom{
  width: 100%;
  margin: 0px;
  margin: 0px !important;
  background-color: #00806e;
}
.min-h-100{
  min-height: 100%;
}
.min-h{
  min-height: 45rem;
}
iframe{
  height: 100%;
  min-height: 45rem;
}

.area-title{
  font-weight: 700;
    margin-bottom: 15px;
    font-size: 1.2rem;
}
.navbar {
  background: #00806e;
}
.navbar .navbar-brand {
  color: #ecf0f1;
}
.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus {
  color: #b8b8b8;
}
.navbar .navbar-text {
  color: #ecf0f1;
}
.navbar .navbar-text a {
  color: #b8b8b8;
}
.navbar .navbar-text a:hover,
.navbar .navbar-text a:focus {
  color: #b8b8b8; 
}
.navbar .navbar-nav .nav-link {
  color: #ecf0f1;
  border-radius: .25rem;
  margin: 0 0.25em;
}
.navbar .navbar-nav .nav-link:not(.disabled):hover,
.navbar .navbar-nav .nav-link:not(.disabled):focus {
  color: #b8b8b8;
}
.navbar .navbar-nav .nav-item.active .nav-link,
.navbar .navbar-nav .nav-item.active .nav-link:hover,
.navbar .navbar-nav .nav-item.active .nav-link:focus,
.navbar .navbar-nav .nav-item.show .nav-link,
.navbar .navbar-nav .nav-item.show .nav-link:hover,
.navbar .navbar-nav .nav-item.show .nav-link:focus {
  color: #b8b8b8;
  background-color: #ffffff;
}
.navbar .navbar-toggler {
  border-color: #ffffff;
}
.navbar .navbar-toggler:hover,
.navbar .navbar-toggler:focus {
  background-color: #ffffff;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  color: #ecf0f1;
}
.navbar .navbar-collapse,
.navbar .navbar-form {
  border-color: #ecf0f1;
}
.navbar .navbar-link {
  color: #ecf0f1;
}
.navbar .navbar-link:hover {
  color: #b8b8b8;
}

@media (max-width: 575px) {
  .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #ecf0f1;
  }
  .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item:hover,
  .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item:focus {
    color: #000000;
  }
  .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item.active {
    color: #000000;
    background-color: #ffffff;
  }
}

@media (max-width: 767px) {
  .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #ecf0f1;
  }
  .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item:hover,
  .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item:focus {
    color: #000000;
  }
  .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item.active {
    color: #000000;
    background-color: #ffffff;
  }
}

@media (max-width: 991px) {
  .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #ecf0f1;
  }
  .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item:hover,
  .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item:focus {
    color: #000000;
  }
  .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item.active {
    color: #000000;
    background-color: #ffffff;
  }
}

@media (max-width: 1199px) {
  .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #ecf0f1;
  }
  .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item:hover,
  .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item:focus {
    color: #000000;
  }
  .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item.active {
    color: #000000;
    background-color: #ffffff;
  }
}

.navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item {
  color: #ecf0f1;
}
.navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item:hover,
.navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item:focus {
  color: #000000;
}
.navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item.active {
  color: #000000;
  background-color: #ffffff;
}




#hero {
  width: 100%;
  height: calc(100vh - 110px);
  background-image:
  linear-gradient(to bottom, rgba(0,128,110,1) 35%, rgba(0,0,0,0) 100%),
  url('./assets/bg.jpg');
  /* background-image:
  linear-gradient(180deg, rgba(0,128,110,1) 37%, rgba(0,0,0,0) 100%),
  url('./assets/bg.jpeg') top center; */
  background-size: cover;
  position: relative;
}

#hero:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
  color: #fff;
}

#hero h2 {
  color: #eee;
  margin-bottom: 30px;
  font-size: 24px;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 10px 28px;
  transition: 0.5s;
  border: 2px solid #fff;
  color: #fff;
}

#hero .btn-get-started:hover {
  background: #01806f;
  border-color: #01806f;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero {
    text-align: center;
  }

  #hero .container {
    padding-top: 40px;
  }

  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
#services {
  width: 100%;
  height: calc(100vh - 110px);
  background-image:
  linear-gradient(to bottom, rgba(0,128,110,1) 35%, rgba(0,0,0,0) 100%),
  url('./assets/bg.jpg');
  /* background-image:
  linear-gradient(180deg, rgba(0,128,110,1) 37%, rgba(0,0,0,0) 100%),
  url('./assets/bg.jpeg') top center; */
  background-size: cover;
  position: relative;
}

.services .icon-box {
  text-align: center;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #01806f;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
  animation: clickit 2s ease 0s infinite normal forwards;
}

.services .icon-box .icon i {
  color: #fff;
  font-size: 28px;
}

.services .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: #18cfb4;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #fff;
}
.services .icon-box h4 a :hover {
  color: #fff;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  background: #00806e;
  border-color: #00674e;
  padding: 70px 20px 90px 20px;
  color: #fff !important;
}

.services .icon-box:hover .icon {
  background: #fff;
}

.services .icon-box:hover .icon i {
  color: #00806e;
}

.services .icon-box:hover .icon::before {
  background: #fff !important;
}

.services .icon-box:hover h4 a,
.services .icon-box:hover p {
  color: #fff !important;
}


/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}

.section-title span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}

.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media (max-width: 575px) {
  .section-title h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .section-title span {
    font-size: 38px;
  }
}
.btn-primary {
  background-color: #01806f !important;
}
.btn-primary:hover, .btn-primary:active, .btn-primary:visited{
  background-color: #00806e !important;
}

.btn {
  border: var(--bs-btn-border-width) solid #00806e !important;
}


@keyframes clickit {
	0% {
		animation-timing-function: ease-out;
		transform: scale(1);
		transform-origin: center center;
	}

	10% {
		animation-timing-function: ease-in;
		transform: scale(0.91);
	}

	17% {
		animation-timing-function: ease-out;
		transform: scale(0.98);
	}

	33% {
		animation-timing-function: ease-in;
		transform: scale(0.87);
	}

	45% {
		animation-timing-function: ease-out;
		transform: scale(1);
	}
}

.here{
  animation: here 2s linear 0s infinite alternate-reverse forwards;
}

@keyframes here {
	0%,
	100% {
		transform: translateX(0%);
		transform-origin: 50% 50%;
	}

	15% {
		transform: translateX(-2px) rotate(-6deg);
	}

	30% {
		transform: translateX(2px) rotate(6deg);
	}

	45% {
		transform: translateX(-2px) rotate(-3.6deg);
	}

	60% {
		transform: translateX(2px) rotate(2.4deg);
	}

	75% {
		transform: translateX(-1px) rotate(-1.2deg);
	}
}

.anio {
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 2rem;
  background-color: #fff;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 1rem;
  margin-right: 1rem;
 }
 .anio :hover{
  background-color: #002821;
  transition: 0.2s ease;
  border-radius: 2rem;
  color: #fff;
 }
 .rowAnios{
  margin-left: 10rem !important;
  margin-right: 10rem !important;
 }
 

 .search {
  width: 100%;
  margin-bottom: 50px;
  padding: 20px;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  -webkit-box-shadow: 0px 0px 6px #666666;
  -moz-box-shadow: 0px 0px 6px #666666;
  box-shadow: 0px 0px 2px #666666;
}

.colcarreras{
    background-color: #fff;
    padding: 2rem;
    margin: 20%;
    border-radius: 2rem;
    text-align: center;
}
.verde{
  color: #00806e !important;
}
.blanco{
  color: #fff !important;
}
.bgverde{
  background-color: #00806e !important;
}

.bold{
    font-family: "Bold" !important;
}
.btn-todos{
  border-radius: 2rem;
}
.btn-ultimoscursos{
  background-color: #fff !important;
  width: 50%;
  color: #666 !important;
  border: none !important;
  border-radius: 4rem
}

.list-group-item:hover{
  background-color: #00806e !important;
  color: white !important;

}

.rowVideos{
  border-radius: 2rem;
}