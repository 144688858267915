
footer {
    background-color: #002821;
    color: white;
    padding: 80px 0;
}
footer .footer-widget h3 {
    margin-bottom: 30px;
}
footer .contact-link {
    display: inline-block;
    width: 100%;
    color: #333;
}
footer .footer-social ul {
    padding-left: 0;
}
footer .footer-social ul li {
    list-style: none;
    float: left;
    padding: 0 10px;
}
footer .footer-social ul li:first-child {
    padding-left: 0;
}
footer .footer-social ul li a {
    font-size: 20px;
    color: #333;
}
footer .footer-social ul li a:hover {
    color: red;
}
